import qs from 'query-string'
import jwt from 'jsonwebtoken'
import Cookies from 'js-cookie'
import lscache from 'lscache'
import { v4 as uuidv4 } from 'uuid'
import ClientOAuth2 from 'client-oauth2'
import firebase from 'firebase/app'
import 'firebase/auth'
import { getTenant, base64urlEncode } from '../util'

export const CALLBACK_PATH = 'callback/'
export const DEFAULT_SESSION_TIMEOUT = 8 * 60 * 60 // 8h
const ALLOWED_DOMAINS_REGEX = /^(https?:\/\/localhost:3000|https:\/\/(\w+\d*-*)+\.retailcloud\.net)/

export const isAllowedDomain = domain => !!domain && !!domain.match(ALLOWED_DOMAINS_REGEX)

const saveCookie = (key, value, options = {}) => {
  const domainParts = window.location.hostname.split('.')
  const domain = !window.location.hostname.includes('localhost')
    ? `${domainParts[domainParts.length - 2]}.${domainParts[domainParts.length - 1]}`
    : null
  Cookies.set(
    key,
    value,
    {
      ...options,
      domain,
    },
  )
}

const removeCookie = (key, options = {}) => {
  const domainParts = window.location.hostname.split('.')
  const domain = !window.location.hostname.includes('localhost')
    ? `${domainParts[domainParts.length - 2]}.${domainParts[domainParts.length - 1]}`
    : null
  Cookies.remove(key, { ...options, domain })
}

export const sendMessageToParent = message => {
  const url = (window.location !== window.parent.location)
    ? document.referrer
    : document.location.href
  if (window.location !== window.parent.location && isAllowedDomain(url)) {
    // The page is in an iframe
    window.parent.postMessage(message, url)
  }
}

const getRedirectData = () => {
  const redirectData = localStorage.getItem('redirectData')
  if (redirectData) {
    localStorage.removeItem('redirectData')
    return JSON.parse(redirectData)
  }
  return qs.parse(window.location.search)
}

const redirectWithToken = token => {
  if (!token) return
  const domainParts = window.location.hostname.split('.')
  const isDev = window.location.hostname.match(/(devconcretelogin|localhost)/)
  const { redirectUrl, referrer, callbackUrl } = getRedirectData()
  sendMessageToParent(`session:${token}`)
  if (callbackUrl && isAllowedDomain(callbackUrl)) {
    return window.location.replace(`${callbackUrl}#id_token=${token}`)
  }

  if (domainParts.length > 2) {
    // we are on a branded domain: xxx.[dev]concretelogin.com
    // redirect back to CP3 website
    return window.location.replace(
      `https://${domainParts[0]}.retailcloud.net/core/application/pPassPolicy/inc/public_authentication_validate.cfm?fbtoken=${token}${redirectUrl ? '&goto_page=' + redirectUrl : ''}`,
    )
  }
  // we are on the base domain, [dev]concretelogin.com, we must be coming from one
  let protocol = 'https:'
  let host = `one${isDev ? '-dev' : ''}.retailcloud.net` // sensible default
  // if a referrer query param is present, we'll use that
  const currentReferrer = referrer ? decodeURIComponent(referrer) : document.referrer
  // sanity check the referrer
  // if for any reason is the login page domain, we'll default it to one[-dev].retailcloud.net
  if (currentReferrer && /(localhost|127.0.0.1|retailcloud\.net)/.test(currentReferrer)) {
    // we'll use the hostname from the referrer
    // we could be coming from any one of:
    // one-dev, one-qa, one-preview, one-preprod, one
    const url = new URL(currentReferrer)
    // localhost is a special case because it is used for testing
    // we'll want to preserve the protocol and the port
    const isLocal = ['localhost', '127.0.0.1'].includes(url.hostname)
    host = url.host
    protocol = isLocal ? url.protocol : protocol
  }
  window.location.replace(
    `${protocol}//${host}/callback${redirectUrl ? '?redirect_to=' + encodeURIComponent(redirectUrl) : ''}#id_token=${token}`,
  )
}

const getTenantConfig = async tenant => {
  if (!tenant) return {}
  try {
    const storageConfig = lscache.get('tenant-config')
    if (storageConfig) return storageConfig
    const response = await fetch(`${process.env.GATSBY_API_URL}sso/${tenant}/configuration`)
    const { configuration = {} } = await response.json()
    if (configuration.sessionTimeout) {
      localStorage.setItem('session-expiry-timeout', (Number(configuration.sessionTimeout) * 60) || DEFAULT_SESSION_TIMEOUT)
    }
    if (Object.keys(configuration).length) lscache.set('tenant-config', configuration, 2 * 60)
    return configuration
  } catch(error) {
    console.error(error)
    return {}
  }
}

const getOauth2Client = async tenant => {
  if (!tenant) return {}
  try {
    const {
      oauthClientId: clientId,
      oauthAuthorizationEndpoint: authorizationUri,
      oauthTokenEndpoint,
      oauthScopes: scopes = ['openid', 'email'],
      oauthUsePKCE,
    } = await getTenantConfig(tenant)
    const redirectUri = `${window.location.origin}/${CALLBACK_PATH}`
    const accessTokenUri = oauthUsePKCE
      ? oauthTokenEndpoint
      : `${process.env.GATSBY_API_URL}sso/${tenant}/token`
    return new ClientOAuth2({
      clientId,
      accessTokenUri,
      authorizationUri,
      redirectUri,
      scopes
    })
  } catch (error) {
    console.error(error)
    return {}
  }
}

const sha256 = plain => {
  const encoder = new TextEncoder()
  const data = encoder.encode(plain)
  return window.crypto.subtle.digest('SHA-256', data)
}

const getCodeVerifier = (length) => {
  const mask = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789-._~'
  let result = ''
  const randomIndices = new Int8Array(length)
  window.crypto.getRandomValues(randomIndices)
  const byteLength = 256
  const maskLength = Math.min(mask.length, byteLength)
  const scalingFactor = byteLength / maskLength;

  for (let i = 0; i < length; i++) {
      result += mask[Math.floor(Math.abs(randomIndices[i]) / scalingFactor)]
  }
  return result;
}

const getCodeChallenge = async () => {
  const codeVerifier = getCodeVerifier(128)
  localStorage.setItem('pkce_code_verifier', codeVerifier)
  const hashed = await sha256(codeVerifier)
  return base64urlEncode(hashed)
}

const getPKCECodeVerifier = ()  => 
  localStorage.getItem('pkce_code_verifier')

const loginWithOpenidProvider = async () => {
  const { redirectUrl, referrer, callbackUrl } = qs.parse(window.location.search)
  const tenant = getTenant()
  const { oauthResponseType, oauthUsePKCE } = await getTenantConfig(tenant)
  const response_type = oauthResponseType && oauthResponseType !== '' ? oauthResponseType : 'id_token'
  const flowType = response_type.includes('code') && !response_type.includes('id_token')
    ? 'code'
    : 'token'
  const oauthClient = await getOauth2Client(tenant)
  const pkceParams = {}
  if (oauthUsePKCE) {
    pkceParams.code_challenge_method = 'S256'
    pkceParams.code_challenge = await getCodeChallenge()
  }
  localStorage.setItem('redirectData', JSON.stringify({ redirectUrl, referrer, callbackUrl }))
  window.location.replace(
    oauthClient[flowType].getUri(
      { 
        query: {
          response_type,
          nonce: uuidv4(),
          state: uuidv4(),
          ...pkceParams,
        }
      },
    )
  )
}

const login = async () => {
  if (window.location.pathname === '/login') return
  const queryParams = qs.parse(window.location.search) || {}
  const queryString = `${qs.stringify({ ...queryParams, referrer: document.referrer })}`
  const tenant = getTenant()
  const { authProviders } = await getTenantConfig(tenant)
  if (authProviders && authProviders.includes('openid') && !authProviders.includes('default')) {
    await loginWithOpenidProvider()
  } else {
    sendMessageToParent(`not-authorized`)
    window.location.replace(`/login?${queryString}`)
  }
}

/**
 * Verifies token returned by SSO provider
 *
 * @param {String} ssoToken 
 */
const verifyToken = async (ssoToken, idClaim) => {
  const tenant = getTenant()
  const response = await fetch(`${process.env.GATSBY_API_URL}sso/${tenant}/verify`, {
    method: 'post',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      token: ssoToken,
      idClaim,
    })
  })
  const { token } = await response.json()
  return token
}

const checkSession = async () => {
  try {
    if (window.location.pathname.includes('resetPassword')) return
    // refresh the session
    firebase.auth().onAuthStateChanged(async user => {
      if (user) {
        const idToken = await user.getIdToken(true)
        // firebase id token have an auth_time claim that represent the timestamp of initial login
        // use that to calculate if session has expired
        const { auth_time } = jwt.decode(idToken)
        const exp = JSON.parse(localStorage.getItem('session-expiry-timeout'))
        const expiryTimeout = exp || DEFAULT_SESSION_TIMEOUT
        const currentTimestamp = Date.now() / 1000
        if (currentTimestamp - auth_time < expiryTimeout) {
          return redirectWithToken(idToken)
        }
      }

      await firebase.auth().signOut()
      await login()
    })
  } catch (error) {
    console.error('An error occurred when checking session: ', error)
    await login()
  }
}

/**
 * Deletes C1 session
 */
const deleteSession = async () => {
  try {
    await fetch(
      `${process.env.GATSBY_API_URL}session`,
      { method: 'DELETE', credentials: 'include' },
    )
  } catch (error) {
    console.error('Error deleting session: ', error)
  }
}

const resetPassword = async (email, password, newPassword) => {
  const response = await fetch(`${process.env.GATSBY_API_URL}auth/resetPassword`, {
    method: 'post',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      email,
      password,
      newPassword,
    }),
  })
  switch (response.status) {
    case 400: throw new Error('Bad request')
    case 403: throw new Error('Unauthorized')
    case 500: throw new Error('Something went wrong')
  }
  const data = await response.json()
  return data
}

export {
  redirectWithToken,
  saveCookie,
  removeCookie,
  getOauth2Client,
  getTenantConfig,
  loginWithOpenidProvider,
  verifyToken,
  checkSession,
  deleteSession,
  getPKCECodeVerifier,
  resetPassword,
}
